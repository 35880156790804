@import url('https://fonts.googleapis.com/css?family=Roboto+Slab:100,300,400,700&display=swap');

body {font-family: "Roboto Slab";}

select, input {
  border:1px solid #d7d6d6;
  border-radius: 5px;
  font-size: 18px; font-style: italic; color:#454545;
  width: 100%;
  height: 40px;
  padding: 0px 15px;
}

button {
  background-color: #d11328;
  border:none;
  color:#fff;
  height: 40px;
  border-radius: 5px;
}

.header {
  background-size: cover;
  background-position: top center;
  max-height:280px;
  height: 40vw;
  margin-bottom: 15px;

}

.header .logo {
  width: 40vw;
  max-width: 220px;
  position: absolute;
  top:30px;
  left:15px;
}

.header .slogan {
  background-color: rgba(0, 0, 0, 0.8);
  color: #a7a8aa;
  font-size: 5vw;
  font-weight: 300;
  max-height: 100px;
  line-height: 100px;
  text-align: right;
  padding-right: 6vw;
  height: 25vw;

box-shadow: 0 0 10px #000;
}

.categories {}

.categorie {
  position: relative;
  background-color: #fff;
  width: 100%;
  transition: 500ms;
  top: 0;
  left: 0;
  margin-bottom: 15px;
  overflow: hidden;
}

.categorie--header {
  border: 1px solid #d7d6d6;
  transition: 500ms;
  height: 120px;
  position: relative;
  border-radius: 10px;
  padding: 20px 20px 20px 35%;
}

.categorie--header .back {
  position: absolute;
  left: 20px;
  height: 25px;
  top: 0;
  bottom: 0;
  margin: auto;
  transform: scale(0);
  transition: 500ms;
}

.categorie.active .categorie--header .back {
  transform: scale(1);
}

.categorie--header .image {
  vertical-align: middle;
  position: absolute;
  left: 0;
  right: auto;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 30%;
  height: 100%;
  transition: 500ms;
  display: flex;
  align-items: center;
  background-size: cover;
  background-position: center center;
}

.categorie--header .name {
  vertical-align: middle;
  font-size: 25px;
  color:#d01328;
  font-weight: 600;
}
.categorie--header .desc {
  color:#454545;
  font-size: 14px;
  overflow: hidden;
transition: 500ms;
}

.categorie.active .categorie--header {
  background-color: #d01928;
  color: #fff;
  border-color: #d01928;
  height: 80px;
  border-radius: 0;
  padding-left: 70px;
}

.categorie.active .categorie--header .image {
  width: 0;
}

.categorie.active .categorie--header .name {color:#fff}
.categorie.active .categorie--header .desc {opacity: 0; position: absolute;}

.categorie--content {
  height: 0;
  transition: 500ms;
  position: absolute;
  background-color: inherit;
  overflow: scroll;
  z-index: 10;
  width: 100%;
}

.categorie--content .childCategorie > .title {
  padding: 20px 0 10px 0;
}

.product {
  margin-bottom: 5px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid #d7d6d6;
}

.product .toggle {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 35px;
  width: 40px;
  height: 40px;
  text-align: center;
  color: #fff;
  background-color: #d11328;
  line-height: 35px;
}

.product > .content {

  padding: 20px;
  position: relative;
  background-color: #fff;
  z-index: 10;
  display: flex;
  
}

.product > .content .title {
  display: inline-block;
  vertical-align: middle;
  font-size: 18px;
  color:#d01328;
  font-weight: 600;
}
.product > .content .desc {
  color: #454545;
  font-size: 14px;
}

#productimage {
  width: 30%;
  height: auto;
  margin-right: 10px;
}

#productimage img {
  max-width: 100%;
  max-height: 100%;
}
#productimage.clone { position: absolute; z-index:999; transition: 500ms ease-in-out}

#productinfo {}

.product > .content .price {
  font-size: 110%;
  color: #d01928;
  font-weight: 600;
  vertical-align: middle;
}

.product > .content .price .eur {
  display: inline-block; 
  width: 25px;
  vertical-align: middle;
  margin-right: 5px;
}

.product .options-wrapper {
  background-color: #f0f0f0;
  padding: 20px;
  border: 1px solid #d7d6d6;
  border-top: 0;
  transition: 500ms;
  position: relative;
  z-index: 5;
}

.product .options-wrapper .options .option {
  margin-bottom: 10px;
}

.product .options-wrapper .options .option label {
  display: block;
}


.product .options-wrapper .quantity {}
.product .options-wrapper .quantity input {
  display: inline-block;
  width: 48%;
  text-align: center;
  vertical-align: bottom;
}
.product .options-wrapper .quantity button {
  display: inline-block;
  width: 48%;
  vertical-align: bottom;
  background-color: #d11328;
  float: right;

}

.shoppingcart-wrapper {
  transition: 750ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
  z-index: 100;
  position: relative;
}

.shoppingcart-wrapper .cartToggle {
  width: 75px;
  height: 75px;
  background-color: red;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  padding-top: 25px;
  position: fixed;
  right: 3%;
  bottom: 3vw;
  transition: inherit;
  z-index: 2;
}

.shoppingcart-wrapper .cart {
  position: fixed;
  bottom: calc(7vw);
  right: calc(7%);
  background-color: #fff;
  overflow: hidden;
  height: 0;
  width: 0;
  padding: 0;
  border: 0px solid red;
  transition: inherit;
  opacity: 0;
}

.shoppingcart-wrapper.active .cartToggle {
  right: calc(97% - 75px);
  bottom: calc(100% - 75px - 3vw);
}
.shoppingcart-wrapper.active .cart {
  height: calc(100% - 14vw);
  width: calc(86%);
  border-width: 1px;
  padding: 20px;
  opacity: 1;
}

.shoppingcart-wrapper .cart h5 {
  text-align: center;
}
